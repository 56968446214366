import { StrictMode, Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./style/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "lazysizes";

import ReactGA from "react-ga";

// pages with normal loading
import ErrorBoundary from "./ErrorBoundary";
// import SuspenseLoader from "./SuspenseLoader";
import Loader from "./Loader";

// pages with lazy loading
const Header = lazy(() => import("./components/layout/header"));
const Footer = lazy(() => import("./components/layout/footer"));
const Home = lazy(() => import("./components/pages/home"));
const LeadershipPage = lazy(() => import("./components/pages/leadershipPage"));
const Disclosure = lazy(() => import("./components/pages/disclosure"));
const RequestForm = lazy(() => import("./components/pages/RequestForm"));
// const SubscribeForm = lazy(() => import("./components/pages/SubscribeModal"));
const NotFoundPage = lazy(() => import("./404"));

// initializing google analytics
if (process.env.REACT_APP_SERVER_ENV === "prod") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
    debug: false, //TODO:
  });
}
ReactDOM.render(
  <StrictMode>
    <Router>
      <ErrorBoundary>
        <Suspense fallback={""}>
          <Header />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/disclosure" component={Disclosure} />
            <Route path="/thought-leadership" component={LeadershipPage} />
            <Route path="/request-form" component={RequestForm} />
            {/* <Route path="/subscribe-form" component={SubscribeForm} /> */}
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </Router>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
