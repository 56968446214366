import { useState, useEffect } from "react";

// image imports
import Spinner from "./assets/spinner_loader.gif";

export default function Loader(props) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div
        style={{
          //   backgroundColor: "#7ac157",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {show && <img src={Spinner} alt="spin" />}
      </div>
    </>
  );
}
